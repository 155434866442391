var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "charg-detail-component" }, [
    _c("div", { staticClass: "detail-name" }, [_vm._v("服务类型")]),
    _c("div", { staticClass: "detail-content" }, [_vm._v("充电扣费")]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("手机号码")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.userPhone)),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("充电站点")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.stationName)),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("开始时间-结束时间")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.dataset.startTime) +
          "--" +
          _vm._s(_vm.dataset.endTime) +
          "\n  "
      ),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("扣费时间")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.endTime)),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("扣费金额")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.totalMoney)),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("充电时长")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.chargeOfTime)),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("充电电量")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.totalPower)),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("充电明细")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(
        "\n    电量：" +
          _vm._s(_vm.dataset.totalPower) +
          "度，电费：" +
          _vm._s(_vm.dataset.elecMoney) +
          "元，服务费：" +
          _vm._s(_vm.dataset.serviceMoney) +
          "元\n  "
      ),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("优惠金额")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.discountMoney)),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("订单编号")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.orderCode)),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("充电桩编码")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.equipmentId)),
    ]),
    _c("div", { staticClass: "detail-name" }, [_vm._v("状态")]),
    _c("div", { staticClass: "detail-content" }, [
      _vm._v(_vm._s(_vm.dataset.orderStatusStr)),
    ]),
    _vm.dataset.invoiceBillNo
      ? _c("div", { staticClass: "detail-name" }, [_vm._v("发票编号")])
      : _vm._e(),
    _vm.dataset.invoiceBillNo
      ? _c("div", { staticClass: "detail-num" }, [
          _vm._v("\n    " + _vm._s(_vm.dataset.invoiceBillNo) + "\n  "),
        ])
      : _vm._e(),
    _vm.dataset.invoiceDate
      ? _c("div", { staticClass: "detail-name" }, [_vm._v("开票时间")])
      : _vm._e(),
    _vm.dataset.invoiceDate
      ? _c("div", { staticClass: "detail-content" }, [
          _vm._v("\n    " + _vm._s(_vm.dataset.invoiceDate) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }