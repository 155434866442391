<template>
  <div class="charg-detail-component">
    <div class="detail-name">服务类型</div>
    <div class="detail-content">充电扣费</div>
    <div class="detail-name">手机号码</div>
    <div class="detail-content">{{ dataset.userPhone }}</div>
    <div class="detail-name">充电站点</div>
    <div class="detail-content">{{ dataset.stationName }}</div>
    <div class="detail-name">开始时间-结束时间</div>
    <div class="detail-content">
      {{ dataset.startTime }}--{{ dataset.endTime }}
    </div>
    <div class="detail-name">扣费时间</div>
    <div class="detail-content">{{ dataset.endTime }}</div>
    <div class="detail-name">扣费金额</div>
    <div class="detail-content">{{ dataset.totalMoney }}</div>
    <div class="detail-name">充电时长</div>
    <div class="detail-content">{{ dataset.chargeOfTime }}</div>

    <div class="detail-name">充电电量</div>
    <div class="detail-content">{{ dataset.totalPower }}</div>
    <div class="detail-name">充电明细</div>
    <div class="detail-content">
      电量：{{ dataset.totalPower }}度，电费：{{
        dataset.elecMoney
      }}元，服务费：{{ dataset.serviceMoney }}元
    </div>
    <div class="detail-name">优惠金额</div>
    <div class="detail-content">{{ dataset.discountMoney }}</div>
    <div class="detail-name">订单编号</div>
    <div class="detail-content">{{ dataset.orderCode }}</div>
    <div class="detail-name">充电桩编码</div>
    <div class="detail-content">{{ dataset.equipmentId }}</div>
    <div class="detail-name">状态</div>
    <div class="detail-content">{{ dataset.orderStatusStr }}</div>
    <div class="detail-name" v-if="dataset.invoiceBillNo">发票编号</div>
    <div class="detail-num" v-if="dataset.invoiceBillNo">
      {{ dataset.invoiceBillNo }}
    </div>
    <div class="detail-name" v-if="dataset.invoiceDate">开票时间</div>
    <div class="detail-content" v-if="dataset.invoiceDate">
      {{ dataset.invoiceDate }}
    </div>
  </div>
</template>

<script>
export default {
  name: "chargPayDetail",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.charg-detail-component {
  .detail-name {
    font-size: 14px;
    color: #999;
    height: 24px;
    line-height: 24px;
  }
  .detail-content {
    font-size: 14px;
    color: #000;
    height: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  .detail-num {
    font-size: 14px;
    color: #409eff;
    height: 24px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}
</style>